<template>
  <div class="ho-card-thumbnail">
    <slot name="image" />

    <!-- ▼▼▼ 左上の配置要素 ▼▼▼ -->
    <template v-if="slots['top-left']">
      <div class="over-item -top-left">
        <slot name="top-left" />
      </div>
    </template>
    <!-- ▲▲▲ 左上の配置要素 ▲▲▲ -->

    <!-- ▼▼▼ 右上の配置要素 ▼▼▼ -->
    <template v-if="slots['top-right']">
      <div class="over-item -top-right">
        <slot name="top-right" />
      </div>
    </template>
    <!-- ▲▲▲ 右上の配置要素 ▲▲▲ -->

    <!-- ▼▼▼ 左下の配置要素 ▼▼▼ -->
    <template v-if="slots['bottom-left']">
      <div class="over-item -bottom-left">
        <slot name="bottom-left" />
      </div>
    </template>
    <!-- ▲▲▲ 左下の配置要素 ▲▲▲ -->

    <!-- ▼▼▼ 右下の配置要素 ▼▼▼ -->
    <template v-if="slots['bottom-right']">
      <div class="over-item -bottom-right">
        <slot name="bottom-right" />
      </div>
    </template>
    <!-- ▲▲▲ 右下の配置要素 ▲▲▲ -->
  </div>
</template>

<script setup lang="ts">
const slots = useSlots()
</script>

<style lang="scss" scoped>
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;

$over-item-offset: v.space(1.5);

.ho-card-thumbnail {
  position: relative;
  // 配置要素
  .over-item {
    position: absolute;
    z-index: 2;

    &.-top-left {
      left: $over-item-offset;
      top: $over-item-offset;
    }

    &.-top-right {
      right: $over-item-offset;
      top: $over-item-offset;
    }

    &.-bottom-left {
      bottom: $over-item-offset;
      left: $over-item-offset;
    }

    &.-bottom-right {
      bottom: $over-item-offset;
      right: $over-item-offset;
    }
  }
}
</style>
